import Vuex from 'vuex'

export enum NotificationType {
  primary = "primary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info"
}

export class Notification {
  
  message:string
  timeout:number
  type: NotificationType

  constructor( message:string, type: NotificationType = NotificationType.info, timeout: number = 3 )
  {
    this.message = message
    this.timeout = timeout
    this.type = type
  }
}

export default class Notify {

  store: any

  constructor(store: any){
    this.store = store
  }

  notification(message: string, type: NotificationType) {
    this.store.dispatch('notifications/createNotification', new Notification(message, type), {root: true})
  }

  primary(message: string) {
    this.notification(message, NotificationType.primary)
  }

  success(message: string) {
    this.notification(message, NotificationType.success)
  }

  info(message: string) {
    this.notification(message, NotificationType.success)
  }

  warning(message: string) {
    this.notification(message, NotificationType.warning)
  }

  error(message: string) {
    this.notification(message, NotificationType.danger)
  }
}