import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {NotificationType, Notification} from '../notify'

@Module({namespaced: true})
export default class NotificationsModule extends VuexModule {

  _list: Notification[] = new Array<Notification>()

  get notifications(){
    return this._list
  }

  @Mutation addNotification( value: Notification ) {
    this._list.push(value)
  }

  @Mutation removeNotification( value: Notification ) {
    const index = this._list.indexOf(value)
    if(index >= 0){
      this._list.splice( index, 1 )
    }
  }

  @Action notifyError( message: string){
    this.context.dispatch('createNotification', new Notification(message, NotificationType.danger))
  }

  @Action notifyWarning( message: string){
    this.context.dispatch('createNotification', new Notification(message, NotificationType.warning))
  }

  @Action notifySuccess( message: string){
    this.context.dispatch('createNotification', new Notification(message, NotificationType.success))
  }

  @Action notifyInfo( message: string){
    this.context.dispatch('createNotification', new Notification(message, NotificationType.info))
  }

  @Action notify( message: string, type: NotificationType){
    this.context.dispatch('createNotification', new Notification(message, type))
  }

  @Action createNotification( value: Notification){
    this.context.commit('addNotification', value)
    setTimeout(() => {
      this.context.commit( "removeNotification", value )
    }, value.timeout * 2000)
  }
}


